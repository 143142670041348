.download-app {
  background-color: #8134af; /* Purple background */
  color: #fff;
  text-align: center;
  padding: 30px;
  border-radius: 20px;
  margin: 30px auto;
  max-width: 800px;
  width: 90%; /* Ensures responsiveness */
}

.download-app h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.app-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap; /* Ensures wrapping for small screens */
}

.app-buttons img {
  width: 160px; /* Keeps buttons at a fixed width */
  height: 50px;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.app-buttons img:hover {
  transform: scale(1.1);
}

/* Responsive */
@media (max-width: 600px) {
  .download-app {
    padding: 20px;
  }
  
  .app-buttons img {
    width: 150px; /* Keeps buttons at a fixed width */
    height: 50px;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
  }

}
