.custom-navbar {
  background-color: #81369e; /* Purple */
  padding:  0;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  height: 80px;
  width: 80px;
}

/* Hamburger Button */
.navbar-toggler {
  border: none;
  background: transparent;
  color: white;
  font-size: 26px;
  cursor: pointer;
  outline: none; /* Removes focus outline */
  box-shadow: none !important; /* Ensures no outline or shadow appears */
}

/* .navbar-toggler:focus {
  outline: none;
} */

/* Navigation Links (Desktop Mode) */
.nav-links {
  display: flex;
  align-items: center;
}

.nav-links .nav-item {
  margin: 0 10px;
}

.nav-links .nav-link {
  color: white !important;
  font-weight: bold;
  padding: 10px 15px;
  
}

.nav-links .nav-link:hover {
  color: #ffcc00 !important;
}

/* Order Button */
.order-btn {
  background-color: #ffcc00;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  transition: 0.3s;
}



/* Mobile Menu Dropdown (Smaller & Right-Aligned) */
.mobile-dropdown {
  position: absolute;
  top: 90px; /* Adjust to match navbar height */
  right: 0; /* Align to right */
  width: 60%; /* Reduce width */
  max-width: 250px; /* Set a max width */
  background-color: #81369e88; /* Match your design */
  z-index: 1000;
  padding: 10px 0;
  box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow towards left */
  backdrop-filter: blur(10px); /* Adds blur effect */
  border-radius: 8px 0 0 8px; /* Rounded left edges */
  margin-right: 10px; /* Add margin to prevent overlap */
}

/* Mobile Nav Styling */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .nav-links .nav-item {
    width: 100%;
    margin: 5px 0;
  }

  .order-btn {
    width: 80%;
    margin: 10px auto;
  }
}
