.hero-section {
  background-color: #c4c4c4;
  padding: 80px 0;
  text-align: left;
  border-top: 5px solid #7d3cb5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* Default background for desktop */
@media (min-width: 769px) {
  .hero-section {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FBannerOptimize.webp?alt=media&token=f41bcf91-4f27-481c-bdbf-4f92138052dc");
  }
}
.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
}

.highlight {
  color: #7d3cb5;
}

.hero-subtitle {
  font-size: 1rem;
  color: white;
  margin-top: 10px;
}

.hero-btn {
  background-color: #7d3cb5;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  margin-top: 20px;
}

@media (max-width: 768px) {
  
  .hero-section {
    padding: 200px 0 50px; /* Increase top padding to push content down */
    text-align: left; /* Center aligns text for better mobile layout */
    background-image: url("https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FBanner_compreWEbP-MOb.webp?alt=media&token=b08b0e27-7079-4ebf-96f3-889c7d187710")
  }

  .hero-title {
    font-size: 2rem; /* Reduce font size for better readability */
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-top: 15px; /* Add more spacing below title */
  }

  .hero-btn {
    margin-top: 30px; /* Push button further down */
    padding: 12px 25px;
    border-radius: 25px;
  }
}
