.footer-section {
  background-color: #8134af;
  color: #fff;
  padding: 40px 20px;
  text-align: left;
}

/* .footer-logo {
  margin-bottom: 15px;
 
} */

/* .logo-img {
  width: 70px;
  border-radius: 50%;
} */

.footer h5 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 6px;
}

.footer-links ul li a {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

.footer-links ul li a:hover {
  text-decoration: underline;
}

.footer-address p {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.footer-address p svg {
  margin-right: 8px;
}

.footer-social .social-icons {
  display: flex;
  justify-content: left;
  gap: 15px;
}

.footer-social .social-icons a {
  color: #fff;
  font-size: 1.5rem;
  transition: 0.3s;
}

.footer-social .social-icons a:hover {
  color: #f1c40f;
}

.copyright {
  margin-top: 20px;
  font-size: 0.85rem;
}

/* Responsive */
@media (max-width: 768px) {
  .footer-section {
    text-align: left;
    padding: 30px 15px;
  }

  .footer-logo {
    margin-bottom: 10px;
  }

  .logo-img {
    width: 50px; /* Adjusted for better mobile fit */
  }

  .footer h5 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .footer-links ul {
    padding: 0;
  }

  .footer-links ul li {
    margin-bottom: 5px;
  }

  .footer-address {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ensuring left alignment */
    gap: 5px;
  }

  .footer-address p {
    flex-direction: row;
    align-items: left;
    justify-content: flex-start; /* Aligning to the left */
  }

  /* .footer-social .social-icons {
    justify-content: flex-start; 
    gap: 12px;
  } */
   
  .footer-social{
    display: none;
  }
  .footer-social .social-icons {
    display: none; /* Hides the social icons */
  }

  .copyright {
    margin-top: 15px;
    font-size: 0.8rem;
    text-align: center; /* Keeping copyright text left-aligned */
  }
}
