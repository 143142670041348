.location-section {
  background-color: #8347AD1A;
  padding: 30px 20px;
  text-align: center;
}

.location-title {
  font-size: 2rem;
  font-weight: bold;
  color: #8134af;
}

.location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.map {
  max-width: 100%; 
  height: auto; 
  border-radius: 5px; 
}

/* Responsive */
@media (max-width: 768px) {
  .location-section {
    padding: 1px 10px;
  }

  .location-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .location-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .map {
    width: 90%; /* Makes sure the image scales well on smaller screens */
  }
}
