.about-section {
    background-color: #ffffff;
    padding: 50px 0;
    text-align: center;
  }
  
  .about-title {
    font-size: 2rem;
    font-weight: bold;
    color: #f5a623;
    margin-bottom: 20px;
  }
  
  .about-text {
    font-size: 1rem;
    color: #000;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
  