.specialties-section {
  background: #f6f0fa;
  padding: 50px 0;
  text-align: center;
  position: relative;
  height: 100%;
}

.specialties-title {
  font-size: 2rem;
  font-weight: bold;
  color: #7d3cb5;
  margin-bottom: 20px;
} 

.specialties-carousel {
  position: relative;
  
  margin: auto;
}

.carousel-image {
  width: 100%;
  max-width: 380px;
  border-radius: 10px;
  display: block;
  margin: auto;
  height: 250px;
}

.dish-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
}

.order-button {
  background: #7d3cb5;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 20px;
  margin-top: 15px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.order-button:hover {
  background: #682ea0;
}

.carousel-controls {
  position: absolute;
  top: 45%;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.carousel-controls button {
  background: #682ea0;
  color: white;
  border: none;
  padding: 10px 17px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.3s;
}

.carousel-controls button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Optional: ensure mobile view remains at 50% */
@media (max-width: 768px) {
  .carousel-controls {
    top: 45%;
    width: 100%;
  }
  .order-button {
    font-size: 0.9rem;
    padding: 8px 15px;
    margin-bottom: 0px;
  }
  .specialties-section {
    padding: 0px 0;
  }
  .carousel-image {
    width: 100%;
    max-width: 330px;
    border-radius: 10px;
    display: block;
    margin: auto;
    height: 235px;
  }
}
