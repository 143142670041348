.terms-container {
    max-width: 90%;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
 
  @media (max-width: 600px) {
    .container {
      max-width: 95%;
      padding: 15px;
    }
  
    h1 {
      font-size: 1.3em;
    }
  
    h2 {
      font-size: 1.2em;
    }
  
    p, li {
      font-size: 0.9em;
    }
  
    a {
      font-size: 1em;
    }
  }
  