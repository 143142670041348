.story-section {
    background-color: #ffffff;
    padding: 50px 0;
    text-align: center;
  }
  
  .story-content {
    font-size: 2rem;
    font-weight: bold;
    color: black;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .story-section {
    padding: 60px 20px;
    background-color: #f8f8f8;
  }
  
  .story-section h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #f5a623;
    margin-bottom: 20px;
  }
  
  
  .story-image {
    width: 100%;
    max-width: 400px;
    border-radius: 6px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .story-section p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    text-align: justify;
    padding: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .story-content {
      flex-direction: column;
    }
  
    .story-image {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  